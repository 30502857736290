/*
 * 🎨 mnml-utils.css 7.0.7
 */

@layer mnml.utils {
  .text--left,
  .text--start {
    text-align: start;
  }
  .text--center {
    text-align: center;
  }
  .text--end,
  .text--right {
    text-align: end;
  }
  .text--caps {
    letter-spacing: 0.005em;
    text-transform: uppercase;
  }
  .text--italic {
    font-style: italic;
  }
  .text--normal {
    font-weight: 400;
  }
  .text--bold {
    font-weight: 700;
  }
  .text--bolder {
    font-weight: bolder;
  }
  .text--small {
    font-size: 0.75rem;
  }
  .text--sans {
    font-family: var(--mnml--font--sans);
  }
  .text--serif {
    font-family: var(--mnml--font--serif);
  }
  .text--mono,
  .text--monospace {
    font-family: var(--mnml--font--monospace);
  }
  .text--nums {
    font-variant: tabular-nums;
  }
  .text--no-wrap {
    white-space: nowrap;
  }
  .text--balance {
    text-wrap: balance;
  }
  .text--underline {
    text-decoration: underline;
  }
  .text--no-underline {
    text-decoration: none;
  }
  .mb-0 {
    margin-block: 0;
  }
  .mb-half {
    margin-block: 0.5rem;
  }
  .mb-1 {
    margin-block: 1rem;
  }
  .mb-2 {
    margin-block: 2rem;
  }
  .mb-auto {
    margin-block: auto;
  }
  .mbs-0 {
    margin-block-start: 0;
  }
  .mbs-half {
    margin-block-start: 0.5rem;
  }
  .mbs-1 {
    margin-block-start: 1rem;
  }
  .mbs-2 {
    margin-block-start: 2rem;
  }
  .mbs-auto {
    margin-block-start: auto;
  }
  .mbe-0 {
    margin-block-end: 0;
  }
  .mbe-half {
    margin-block-end: 0.5rem;
  }
  .mbe-1 {
    margin-block-end: 1rem;
  }
  .mbe-2 {
    margin-block-end: 2rem;
  }
  .mbe-auto {
    margin-block-end: auto;
  }
  .mi-0 {
    margin-inline: 0;
  }
  .mi-half {
    margin-inline: 0.5rem;
  }
  .mi-1 {
    margin-inline: 1rem;
  }
  .mi-2 {
    margin-inline: 2rem;
  }
  .mi-auto {
    margin-inline: auto;
  }
  .mis-0 {
    margin-inline-start: 0;
  }
  .mis-half {
    margin-inline-start: 0.5rem;
  }
  .mis-1 {
    margin-inline-start: 1rem;
  }
  .mis-2 {
    margin-inline-start: 2rem;
  }
  .mis-auto {
    margin-inline-start: auto;
  }
  .mie-0 {
    margin-inline-end: 0;
  }
  .mie-half {
    margin-inline-end: 0.5rem;
  }
  .mie-1 {
    margin-inline-end: 1rem;
  }
  .mie-2 {
    margin-inline-end: 2rem;
  }
  .mie-auto {
    margin-inline-end: auto;
  }
  .pb-0 {
    padding-block: 0;
  }
  .pb-half {
    padding-block: 0.5rem;
  }
  .pb-1 {
    padding-block: 1rem;
  }
  .pb-2 {
    padding-block: 2rem;
  }
  .pbs-0 {
    padding-block-start: 0;
  }
  .pbs-half {
    padding-block-start: 0.5rem;
  }
  .pbs-1 {
    padding-block-start: 1rem;
  }
  .pbs-2 {
    padding-block-start: 2rem;
  }
  .pbe-0 {
    padding-block-end: 0;
  }
  .pbe-half {
    padding-block-end: 0.5rem;
  }
  .pbe-1 {
    padding-block-end: 1rem;
  }
  .pbe-2 {
    padding-block-end: 2rem;
  }
  .pi-0 {
    padding-inline: 0;
  }
  .pi-half {
    padding-inline: 0.5rem;
  }
  .pi-1 {
    padding-inline: 1rem;
  }
  .pi-2 {
    padding-inline: 2rem;
  }
  .pis-0 {
    padding-inline-start: 0;
  }
  .pis-half {
    padding-inline-start: 0.5rem;
  }
  .pis-1 {
    padding-inline-start: 1rem;
  }
  .pis-2 {
    padding-inline-start: 2rem;
  }
  .pie-0 {
    padding-inline-end: 0;
  }
  .pie-half {
    padding-inline-end: 0.5rem;
  }
  .pie-1 {
    padding-inline-end: 1rem;
  }
  .pie-2 {
    padding-inline-end: 2rem;
  }
}
